export const validateForm = (
  values = {},
  bodyFormLength = 0,
  bodyRequiredLength = 0,
  haveEvent = false
) => {

  // console.log("Validation Form");
  // console.log(values);
  // console.log(bodyFormLength);
  // console.log(bodyRequiredLength);

  if (haveEvent.length) {
    if (values.event) {
      if(!Object.values(values.event).length){
        return false
      }else{
        delete values.event
      }
    }else{
      return false
    }
  }


  let bodyRequired = bodyRequiredLength
  const isMyObjectEmpty = !Object.keys(values).length
  if (isMyObjectEmpty && bodyFormLength > 0 && bodyRequired !== 0) {
    return false
  } else if (bodyRequired === 0) {
    return true
  } else {
    const bodyFormEntries = Object.entries(values)

    let flag = true

    for (let index = 0; index < bodyFormEntries.length; index++) {
      if (!bodyFormEntries[index][0].includes('_')) {
        bodyRequired = bodyRequired - 1
      }
    }

    for (let i = 0; i < bodyFormEntries.length; i++) {
      if (
        bodyFormEntries[i][1].length < 3 &&
        !bodyFormEntries[i][0].includes('_')
      ) {
        flag = false
        break
      }
      if (bodyRequired !== 0) {
        flag = false
        break
      }
    }
    return flag
  }
}

export const validateEmail = (mail) => {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      mail
    )
  ) {
    return true
  }
  return false
}

export const validatePhoneNumber = (number) => {
  if (/^(\+\d{1,3}( )?)((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4}$/im.test(number)) {
    // Expresion regular SIN codigo Pais --- /^[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,7}$/im.test(number) ---
    return true
  }
  return false
}

export const validateContent = (validate, value) => {
  if (validate) {
    return !!value
  } else {
    return true
  }
}
